.menu_group_container {
    display: flex;
    flex-direction: column;
}

.menu_item_group_header {
    color: #687078;
    font-weight: 400;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
}

.menu_group_container.high_level > .menu_item_group_header {
    font-weight: 500;
    font-size: 1rem;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
}
