.entity-list-table-container {
    width: inherit;
    overflow-x: scroll;
}

.entity-list-table-loader-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.entity-list-table-empty-container {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 32px 0;
    justify-content: center;
}

.entity-list-table-empty-container .title {
    font-weight: 600;
}
