.rp4-menu-group-container {
    padding-top: 8px;
    padding-left: 18px;

    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.rp4-menu-group-container > label {
    color: #687078;
    font-weight: 400;
    font-size: 0.9rem;
}

.rp4-menu-item {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    padding-right: 18px;
}

.rp4-menu-item:hover {
    cursor: pointer;
}

.rp4-menu-item.selected:before {
    position: absolute;
    content: ' ';
    background-color: #ec7211;
    height: 8px;
    width: 8px;
    left: -20px;
    top: calc(100% / 2 - 4px);
    border-radius: 4px;
}

.rp4-menu-item-name {
    display: inline-block;
    min-width: 70px;
}

.rp4-menu-item-desc {
    color: #687078;
    font-weight: 400;
    margin-left: 10px;
    font-size: 0.9rem;

    display: inline-block;
    min-width: 75px;
}