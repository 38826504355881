.menu_container {
    width: 280px;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;

    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,28,36,.3),1px 1px 1px 0 rgba(0,28,36,.15),-1px 1px 1px 0 rgba(0,28,36,.15);
}

.menu_container > .action_container {
    padding: 0.8rem 12px 0 12px;
}

.list_container {
    padding: 0 18px;
    overflow-y: scroll;
}