.toc_item_label_container {
    display: flex;
    flex-direction: row;

    padding-top: 2px;
    padding-bottom: 2px;
}

.toc_item_label_no_tables {
    color: rgba(22, 25, 31, 0.6);
}

.toc_item_label_page_container {
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    justify-content: center;
}
