.report_table_container {
    overflow-x: scroll;
}

.report_table {
    width: 100%;
    font-size: 14px;
    border-spacing: 0;
}

.report_table td {
    border-left: 1px solid #b4b4b4;
    border-bottom: 1px solid #b4b4b4;
    padding: 4px 8px;

    tab-size: 8;
}

.report_table td:last-child {
    border-right: 1px solid #b4b4b4;
}

.report_table tr:first-child td:not(.column_number) {
    border-top: 1px solid #b4b4b4;
}

.report_table td.header {
    background-color: rgb(234, 237, 237);
}

.report_table td.data_numeric {
    text-align: right;
}

.report_table td.indent_1 {
    padding-left: 32px;
}
.report_table td.indent_2 {
    padding-left: 48px;
}
.report_table td.indent_3 {
    padding-left: 64px;
}
.report_table td.indent_4 {
    padding-left: 80px;
}

.report_table td.column_number {
    color: #b4b4b4;
    text-align: center;
    border-left: 0;
    border-right: 0;
}

.report_table td.row_number {
    overflow: hidden;
    white-space: nowrap;

    color: #b4b4b4;
    text-align: center;

    border-left: 0;
    border-bottom: 0;

    padding: 4px 8px 4px 0;
    width: 10px;
}

.report_table tr:first-child td.row_number {
    border-top: 0;
}

.report_table td.gutter {
    border-left: 0;
    border-bottom: 0;
    padding: 4px 8px 4px 0;
    width: 10px;
}

.report_table tr:first-child td.gutter {
    border-top: 0;
}