.add_revision_btn_wrapper {
    display: flex;
    justify-content: center;
}

.section_subheader {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.entity_card_container {
    padding-right: 1rem;
    display: grid;
    grid-template-columns: 1fr 40px;
}

.entity_card {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.type_block_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
}

.entity_card_control {
    display: flex;
    flex-direction: column;
}