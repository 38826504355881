.content_block {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 32px;
    row-gap: 16px;

    min-height: 300px;
}

/* ---------------- chart_block ---------------- */
.chart_block {
    flex-grow: 1;
    flex-basis: 320px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    width: 100%;
}

.chart_block_control_plane {
    display: flex;
    flex-direction: column;
}

.chart_block_content {
    flex-grow: 1;
}

/* ---------------- metric_block ---------------- */
.metric_block {
    flex-grow: 1;
    flex-basis: 320px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

.metric_block_avg_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    margin-top: 32px;;
    margin-bottom: 32px;;
}

.metric_block_avg_title {
    font-weight: 500;
    font-size: 1.7rem;
}

.metric_block_avg_value {
    font-size: 1.7rem;
}

.metric_block_other_container {
    display: grid;
    grid-template-columns: minmax(150px, 200px) 1fr;
    gap: 16px;
}

.metric_block_other_title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.metric_block_other_value {
    align-self: center;
}

/* ---------------- divider ---------------- */
.divider {
    margin: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    height: auto;
    border-right-width: thin;
    align-self: stretch;
}

@media (max-width: 1038px) {
    .divider {
        width: 100%;
        margin: 0;
        flex-shrink: 0;
        border-width: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.12);
        border-bottom-width: thin;
    }
}