.editor_control_header {
    padding: 6px 2rem;
    box-sizing: border-box;
}

.entity_selectors_container {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 160px 1fr;
    /*grid-auto-rows: 40px; */
    gap: 8px 16px;
}

.entity_selector_warning_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.entity_selector_warning_text {
    font-size: 0.9rem;
    color: rgba(22, 25, 31, 0.6);
}
