.inspections_container {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.inspection_container {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid #dadde9;
    border-radius: 4px;
    padding: 4px 8px;
    box-shadow: 0 0 5px 2px rgba(0,28,36,.15);
}

.inspection_header_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    margin-bottom: 4px;

    font-size: 1rem;
}

.inspection_body_container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    font-size: 0.85rem;
    min-width: 200px;
}

.inspection_description_container {
    display: flex;
    flex-direction: column;
}

.inspection_description_container > span {
    padding-left: 32px;
}

.inspection_description_container ul {
    margin: 4px 0 0;
    padding: 0;
}

.inspection_description_container li {
    list-style: none;
    margin-bottom: 2px;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
}

.aed_item_label {
    display: inline-block;
    min-width: 80px;
    width: 80px;
}

.aed_item_value {
    display: inline-block;
    width: calc(100% - 112px);
    text-align: right;
}
