.report-steps-workspace {
    max-width: 900px;
    width: calc(100% - 64px);
}

.report-steps-item-container {
    max-width: 450px;
}

.report-steps-item-header {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
}

.report-steps-item-description {
    display: flex;
    flex-direction: column;

    font-size: 14px;
    color: rgba(22, 25, 31, 0.6);

    padding-top: 12px;
    padding-bottom: 12px;
}

.report-steps-item-description-item {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.report-steps-item-description-item > .title {
    white-space: nowrap;
}

.report-steps-item-description-item > .value {

}

