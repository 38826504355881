.block_body {
    min-height: 100px;
    max-height: 300px;
    overflow-y: scroll;
}

.table_block:not(:last-child) {
    margin-bottom: 15px;
}

.table_block .subheader {
    font-weight: 500;
}
