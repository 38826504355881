.entity_menu_item_container {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    padding-right: 18px;
}

.entity_menu_item_container:hover {
    cursor: pointer;
}

.entity_menu_item_container.selected:before {
    position: absolute;
    content: ' ';
    background-color: #ec7211;
    height: 8px;
    width: 8px;
    left: -20px;
    top: calc(100% / 2 - 4px);
    border-radius: 4px;
}

.entity_menu_item_body {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    max-width: calc(100% - 30px);
}

.entity_menu_item_name {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entity_menu_item_features {
    display: flex;
    flex-direction: row;
    gap: 4px;
    flex-wrap: wrap;
}