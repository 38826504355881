.enum_values_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;

    max-width: 500px;
}

.no_items_value {
    color: #687078;
    font-weight: 400;
    font-size: 0.85rem;
}