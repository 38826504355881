.menu_item_container {
    margin: 1rem 0;
    list-style: none;
    font-weight: 400;
}

.menu_item_container:first-child {
    margin-top: 0;
}

.menu_item_container.skeleton {
    padding-right: 40px;
}

.menu_item_container a {
    text-decoration: none;
    color: black;
}

.menu_item_container a:hover {
    color: #ec7211;
}

.menu_item {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-right: 18px;
}

.menu_item:hover {
    cursor: pointer;
}

.menu_item.selected:before {
    position: absolute;
    content: ' ';
    background-color: #ec7211;
    height: 8px;
    width: 8px;
    left: -20px;
    top: calc(100% / 2 - 4px);
    border-radius: 4px;
}

.menu_item_page_num {
    display: inline-block;
    min-width: 70px;
}

.menu_item_page_tables {
    color: #687078;
    font-weight: 400;
    margin-left: 10px;
    font-size: 0.9rem;

    display: inline-block;
    min-width: 75px;
}
