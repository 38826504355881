.report-bottom-sticky-container {
    position: sticky;
    bottom: 0;
    margin-bottom: 15px;
    width: 100%;
}

.report-bottom-sticky-container:last-child {
    margin-bottom: unset;
}
