.label-container {
    display: inline-flex;
    align-items: center;

    border-radius: 4px;
    padding: 2px 12px;
    gap: 4px;
}

.label-container.actionable {
    cursor: pointer;
}

.label-container > .text {
    line-height: 20px;
    white-space: nowrap;
}

.label-container.large {
    border-radius: 8px;
    padding: 6px 12px;
}

.label-container.large > .text {
    line-height: 20px;
}

.label-container.small {
    border-radius: 4px;
    padding: 2px 6px;
}

.label-container.small > .text {
    line-height: 14px;
    font-size: 14px;
}

.label-container.success {
    color: rgb(7, 163, 0);
    background-color: rgba(59, 201, 53, 0.25);
}

.label-container.error {
    color: #D8000C;
    background-color: #FFD2D2;
}

.label-container.info {
    color: #027bf3;
    background-color: rgba(2,123,243,0.20);
}

.label-container.warning {
    color: #ff8800;
    background-color: rgba(255, 136, 0, 0.20);
}

.label-container.disabled {
    color: rgba(22, 25, 31, 0.6);
    background-color: rgba(170, 183, 184, 0.35);
}

.label-container.excluded {
    color: rgba(22, 25, 31, 0.6);
    background-color: rgba(170, 183, 184, 0.35);
    text-decoration: line-through
}
