.menu_item_value {
    display: inline-block;
    min-width: 70px;
}

.menu_item_desc {
    color: #687078;
    font-weight: 400;
    margin-left: 10px;
    font-size: 0.9rem;
}