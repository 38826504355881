.rp2-menu-item-page {
    display: inline-block;
    min-width: 70px;
}

.rp2-menu-item-desc {
    color: #687078;
    font-weight: 400;
    margin-left: 10px;
    font-size: 0.9rem;
}

/*---------------------------------------------*/

.rp2-control-pane-workspace-body {
    min-height: 100px;
    max-height: 300px;
    overflow-y: scroll;
}

