.context_container {
    padding-bottom: 24px;
}

.context_container div[role="separator"] {
    padding-bottom: 8px;
}

.cell_context_body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 24px;
}

.source_context_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 24px;
}

.filed_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
}

.filed_container > label {
    align-self: flex-start;
    min-width: 120px;
    padding-top: 10px;
}

.filed_container > .cell_value_container {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.filed_container > .cell_currency_value_container {
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
}

.filed_container > .feature_value_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 4px;
}

.page_group_header_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 1.2rem;
    font-weight: 400;

    margin-bottom: 8px;
}
