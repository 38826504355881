.container {
    flex-grow: 1;
    max-width: 80%;
}

.title_value_container {
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.title_value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title_value:first-letter {
    text-transform: uppercase;
}

.title_description_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px
}

.title_id_container {
    color: #687078;
    font-weight: 400;
    font-size: 0.85rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title_id_container > :not(:first-child) {
    padding-left: 4px;
}
