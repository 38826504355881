.header_dg_wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 4px;
}

.header_dg_wrapper[data-subheader=true] {
    flex-direction: row;
    align-items: center;
}

.header_dgs_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
}
