.container {
    color: rgba(22, 25, 31, 0.6);
    background-color: rgba(170, 183, 184, 0.35);

    border-radius: 8px;
    padding: 6px 12px;
    margin-bottom: 1rem;

    display: flex;
    align-items: center;

    overflow: hidden;
    min-width: 400px;
}

.label {
    white-space: nowrap;
    margin-right: 8px;
}

.value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}