.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 6px 12px;
    border-radius: 12px;

    font-size: 0.9rem;
    font-weight: 400;
}

.container.enabled {
    color: rgb(7, 163, 0);
    background-color: rgba(59, 201, 53, 0.25);
}

.container.disabled {
    color: rgba(22, 25, 31, 0.6);
    background-color: rgba(170, 183, 184, 0.35);
}