.settings_group_container > div:not(:last-child) {
    margin-bottom: 32px;
}

.settings_group_container .group_title {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 4px;
    margin-bottom: 8px;
}

.settings_group_container .subtitle {
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 4px;
    margin-bottom: 8px;
}