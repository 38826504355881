.chart_block {
    height: 200px;
    overflow: hidden;
}

.metric_block {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
}

.metric_block_other_container {
    display: grid;
    grid-template-columns: minmax(150px, 200px) 1fr;
    gap: 16px;
}