td.failed_rule_result {
    color: #D8000C;
    background-color: #FFD2D2;
    cursor: pointer;
}

td.passed_rule_result {
    color: rgb(7, 163, 0);
    background-color: rgba(59, 201, 53, 0.25);
    cursor: pointer;
}

td.rule_ref {
    color: #027bf3;
    background-color: rgba(2,123,243,0.20);
}

td.validation_rule_ref {
    color: #723efd;
    background-color: rgba(114, 62, 253, 0.25);
}

.rule_result_workspace {
    position: sticky;
    bottom: 15px;
    margin-bottom: 15px;
}

.rule_result_workspace_body {
    max-height: 300px;
    height: 300px;
    overflow-y: scroll;
}

.detached_row  {
    background: repeating-linear-gradient(
            -45deg,
            rgba(234, 237, 237, 1),
            rgba(234, 237, 237, 1) 10px,
            rgba(234, 237, 237, 0.5) 10px,
            rgba(234, 237, 237, 0.5) 20px
    );
}
