.menu_container {
    width: 280px;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;

    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,28,36,.3),1px 1px 1px 0 rgba(0,28,36,.15),-1px 1px 1px 0 rgba(0,28,36,.15);

    overflow-y: scroll;
}

.menu_container > .action_container {
    padding: 0.8rem 12px 0 12px;
}

.menu_group_container {
    padding-top: 8px;
    padding-left: 18px;

    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.menu_group_container > label {
    color: #687078;
    font-weight: 400;
    font-size: 0.9rem;
}


.menu_list {
    margin: 0;
    padding-left: 22px;
}

.menu_group_container {
    padding-top: 8px;
    padding-left: 18px;

    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.menu_group_container > label {
    color: #687078;
    font-weight: 400;
    font-size: 0.9rem;
}

.menu_item_container {
    margin: 1rem 0;
    list-style: none;
    font-weight: 400;
}

.menu_item_container:first-child {
    margin-top: 0;
}

.menu_item_container a {
    text-decoration: none;
    color: black;
}

.menu_item_container a:hover {
    color: #ec7211;
}

.menu_item {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-right: 18px;
}

.menu_item:hover {
    cursor: pointer;
}

.menu_item.selected:before {
    position: absolute;
    content: ' ';
    background-color: #ec7211;
    height: 8px;
    width: 8px;
    left: -20px;
    top: calc(100% / 2 - 4px);
    border-radius: 4px;
}