.container {
    width: 300px;
    margin: 15px;
}

.header_container {
    font-size: 18px;
    font-weight: 500;

    display: flex;
    align-items: center;

    padding-bottom: 16px;

    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
    border-bottom-width: thin;
}

.content_container {
    display: flex;
    flex-direction: column;

    padding-top: 24px;
    padding-bottom: 16px;
}