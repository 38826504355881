.tooltip_container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.header_container {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;

    font-size: 16px;
}

.value_container label {
    font-size: 14px;
}

.list_container {
    margin: 8px 0 0;
    padding-left: 16px;
}
