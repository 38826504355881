.project_editor_block {
    max-width: 800px;
    width: 100%;
}

.project_editor_action_container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-end;
}