body {
    background-color: #efefef;
}

html, body {
    height: 100%;
}

#root {
    height: 100%;
}

.on_maintenance_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    row-gap: 8px;
    height: 100%;
}

.on_maintenance_container .title {
    font-size: 32px;
}

.on_maintenance_container .desc {
    color: #687078;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
}
