.container {
    width: 300px;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;

    padding-right: 8px;
    padding-left: 8px;

    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,28,36,.3),1px 1px 1px 0 rgba(0,28,36,.15),-1px 1px 1px 0 rgba(0,28,36,.15);
}

.container a {
    text-decoration: none;
    color: black;
}

.action_container {
    padding-top: 0.8rem;
}

.menu_container {
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 8px;
    row-gap: 8px;
}

.report_menu_item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.report_menu_item > label {
    font-size: 1rem;
    font-weight: 500;
    color: #687078;
}

.report_menu_item ul {
    margin: 0;
    padding: 0;
}

.revision_menu_item {
    list-style: none;
    margin-bottom: 2px;
}

.revision_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
}

.revision_container:hover {
    background-color: rgba(170, 183, 184, 0.35);
    border-radius: 4px;
}

.revision_container[data-selected=true] {
    background-color: rgba(170, 183, 184, 0.35);
    border-radius: 4px;
    font-weight: 500;
}