.report-container-empty {
    width: inherit;

    display: flex;
    flex-direction: column;

    align-items: center;

    padding: 2rem 2rem;
}

.report-container-empty div:not(:last-child) {
    margin-bottom: 16px;
}

.report-container-empty .title {
    font-weight: 600;
}
