.dialog_group_container:not(:last-child) {
    margin-bottom: 18px;
}

.dialog_group_container .subtitle {
    font-size: 1.1rem;
    font-weight: 400;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 4px;
    margin-bottom: 8px;
}