.items_container {
    width: 500px;
    display: grid;
    grid-template-columns: 250px 1fr 30px;
    gap: 16px
}

.items_container > span {
    align-self: center;
}

.file_name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
