.cell_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.text_fragment {
    font-weight: 400;
}

.list_item_tag {
    color: #687078;
    min-width: 18px;
    display: inline-block;
}

.prefix_tag {
    color: #687078;
    font-weight: 400;
}

.tag_container {
    display: inline-block;
}