.info_container {
    padding: 0 16px 8px;
    min-height: 35px;

    display: flex;
    align-items: center;
}

.no_item_container {
    font-size: 0.9rem;
    font-weight: 300;
    color: #687078;
}

.info_field_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 4px 0;
    max-width: 300px;
}

.info_field_container > label {
    font-size: 0.9rem;
    font-weight: 300;
    color: #687078;
    min-width: 100px;
}