.editor_control_header {
    padding: 6px 2rem;
    box-sizing: border-box;
}

.sub_item_container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    column-gap: 32px;
    row-gap: 16px;

    min-height: 300px;
    max-height: 600px;
}

.sub_item_tree_container {
    flex-grow: 1;
    flex-basis: 400px;
    overflow-y: scroll;
}

.sub_item_editor_container {
    flex-grow: 1;
    flex-basis: 400px;
    overflow: hidden;
}

.divider {
    margin: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    height: auto;
    border-right-width: thin;
    align-self: stretch;
}

@media (max-width: 1238px) {
    .divider {
        width: 100%;
        margin: 0;
        flex-shrink: 0;
        border-width: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.12);
        border-bottom-width: thin;
    }

    .sub_item_container {
        flex-direction: column;
    }
}

.no_item_editor_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}