.content_block {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 32px;
    row-gap: 16px;

    min-height: 300px;
}

.stats_block {
    flex-grow: 1;
    flex-basis: 320px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    width: 100%;
}

/* ---------------- divider ---------------- */
.divider {
    margin: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    height: auto;
    border-right-width: thin;
    align-self: stretch;
}

@media (max-width: 1038px) {
    .divider {
        width: 100%;
        margin: 0;
        flex-shrink: 0;
        border-width: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.12);
        border-bottom-width: thin;
    }
}