td.ref_suppressed {
    color: rgba(22, 25, 31, 0.6);
    background-color: rgba(170, 183, 184, 0.35);
}

td.ref_not_resolved {
    color: #ff8800;
    background-color: rgba(255, 136, 0, 0.20);
    cursor: pointer;
}

td.ref_not_valid {
    color: #D8000C;
    background-color: #FFD2D2;
    cursor: pointer;
}

td.ref_valid {
    color: rgb(7, 163, 0);
    background-color: rgba(59, 201, 53, 0.25);
    cursor: pointer;
}