.rp5-menu-item-page {
    display: inline-block;
    min-width: 70px;
}

.rp5-menu-item-desc {
    color: #687078;
    font-weight: 400;
    margin-left: 10px;
    font-size: 0.9rem;

    display: inline-block;
    min-width: 75px;
}
