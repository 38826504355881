.menu_item_list {
    margin: 0;
    padding-left: 22px;
}

.menu_item {
    margin: 1rem 0;
    list-style: none;
    font-weight: 400;
}

.menu_item:first-child {
    margin-top: 8px;
}

.menu_item a {
    text-decoration: none;
    color: black;
}

.menu_item a:hover {
    color: #ec7211;
}

.menu_item_content {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.menu_item_content.selected:before {
    position: absolute;
    content: ' ';
    background-color: #ec7211;
    height: 8px;
    width: 8px;
    left: -20px;
    top: calc(100% / 2 - 4px);
    border-radius: 4px;
}