.selector_list_container {
    flex-direction: column;
}

.empty_list_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    color: rgba(22, 25, 31, 0.6);
    font-size: 0.9rem;
    font-style: italic;
}


.toolbar_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;

    background-color: rgba(170, 183, 184, 0.35);

    border-radius: 8px;
    padding: 4px 8px;
    margin-bottom: 12px;
}

.body_container {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 150px 1fr;
    grid-auto-rows: 40px;
    align-items: center;
    gap: 8px 16px;
}

.body_container_readonly {
    width: 100%;
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-auto-rows: 40px;
    align-items: center;
    gap: 8px 16px;
}