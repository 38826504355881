.settings_dialog_body {
    width: 500px;
    display: flex;
    flex-direction: row;
    column-gap: 32px;
}

.settings_container {
    flex-grow: 1;
    flex-basis: 100px;
}