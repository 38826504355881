.container {
    min-height: 40px;
}

.container:not(:last-child) {
    margin-bottom: 8px;
}

.value_container {
    display: flex;
    align-items: center;
    min-height: 38px;
}

.description_container {
    color: #687078;
    font-weight: 400;
    font-size: 0.9rem;
}