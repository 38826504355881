.container {
    width: 230px;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;

    background-color: #f9f9f9;
    box-shadow: 0 1px 1px 0 rgba(0,28,36,.3),1px 1px 1px 0 rgba(0,28,36,.15),-1px 1px 1px 0 rgba(0,28,36,.15);
}

.menu_container {
    padding: 0;
    box-sizing: border-box;
}

.menu_container li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu_container a {
    text-decoration: none;
}

.menu_item_container {
    margin: 1px 8px;
    padding: 0.5rem 0.75rem;

    font-weight: 400;
    color: rgb(51, 50, 56);

    cursor: pointer;

    display: flex;
    align-items: center;
    column-gap: 8px;
}

.menu_item_container:first-child {
    margin-top: 0;
}

.menu_item_container:hover {
    background-color: rgba(170, 183, 184, 0.35);
    border-radius: 4px;
}

.menu_item_container[data-selected=true] {
    background-color: rgba(170, 183, 184, 0.35);
    border-radius: 4px;
    font-weight: 500;
}

.menu_item_container .icon {
    display: flex;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
}