.rp6-menu-item-page {
    display: inline-block;
    min-width: 70px;
}

.rp6-menu-item-desc {
    color: #687078;
    font-weight: 400;
    margin-left: 10px;
    font-size: 0.9rem;

    display: inline-block;
    min-width: 75px;
}

.no_refs {
    color: #687078;
}

/*---------------------------------------------*/

.rp6-data-table {
    width: 100%;
    font-size: 14px;
    border-spacing: 0;
}

.rp6-data-table td {
    border-left: 1px solid #b4b4b4;
    border-bottom: 1px solid #b4b4b4;
    padding: 4px 8px;
}

.rp6-data-table td:last-child {
    border-right: 1px solid #b4b4b4;
}

.rp6-data-table tr:first-child td {
    border-top: 1px solid #b4b4b4;
}

.rp6-data-table td.header {
    background-color: rgb(234, 237, 237);
}

.rp6-data-table td.data-numeric {
    text-align: right;
}

.rp6-data-table td.failed_rule {
    color: #D8000C;
    background-color: #FFD2D2;
}

.rp6-data-table td.passed_rule {
    color: rgb(7, 163, 0);
    background-color: rgba(59, 201, 53, 0.25);
}

.rp6-data-table td.cell_ref {
    color: #027bf3;
    background-color: rgba(2,123,243,0.20);
}

/*---------------------------------------------*/

.rp6-control-pane-workspace-body {
    max-height: 300px;
    height: 300px;
    overflow-y: scroll;
}
