.container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.container > label {
    color: #687078;
    font-size: 14px;
    font-weight: 400;
}

.controller {
    min-width: 180px;
}