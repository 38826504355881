td.ref_cell {
    color: #027bf3;
    background-color: rgba(2,123,243,0.20);
}

td.possible_ref_cell {
    color: #ff8800;
    background-color: rgba(255, 136, 0, 0.20);
}

td.collapsed_part {
    background: repeating-linear-gradient(
            -45deg,
            rgba(234, 237, 237, 1),
            rgba(234, 237, 237, 1) 10px,
            rgba(234, 237, 237, 0.5) 10px,
            rgba(234, 237, 237, 0.5) 20px
    );
}

td .value_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    gap: 8px;
}