.file_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.attachment_list_item {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    align-items: center;
    padding: 2px 8px;
}

.attachment_list_item:hover {
    background-color: rgba(170, 183, 184, 0.35);
    border-radius: 6px;
}

.attachment_icon {
    height: 24px;
    width: 24px;
    align-self: center;
    justify-self: center;
}

.attachment_content {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    overflow-x: hidden;
}

.attachment_actions {
    visibility: hidden;
}

.attachment_list_item:hover .attachment_actions {
    visibility: visible;
}